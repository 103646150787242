<template>
  <div class="app">
    <div class="app-content">
      <!-- 搜索 -->
      <!-- <div class="searchSlit">
        <el-input
          v-model="input"
          style="width: 11.25rem"
          placeholder="请输入内容"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          style="width: 5rem; height: 2.1875rem; margin-left: 0.9375rem"
          >搜索</el-button
        >
      </div> -->

      <div class="distributionLayout">
        <div
          class="variousLayout"
          v-for="(item, index) in list"
          :key="index"
          @click="turnToDeatils(item.name)"
        >
          <img :src="srcPic + item.uri" alt="" width="100%" />
          <div class="son_title">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { headGuideList } from '@/api/HomePage/guide.js';
export default {
  components: {},
  data() {
    return {
      input: '',
      srcPic: '',
      value: '',
      list: [],
      argument: {
        name: '',
      },
      myId: '', //侦听路由id
    };
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      handler(newId, oldId) {
        this.myId = newId;
      },
    },
  },
  methods: {
    turnToDeatils(name) {
      this.argument = {
        name: name,
      };
      this.$store.dispatch('headGuideList', this.argument);
      this.$router.push({
        path: '/layout/companyProfile/etcguideDetails',
        query: {
          name: name,
          id: this.myId,
        },
      });
    },
    getList() {
      headGuideList().then((request) => {
        this.list = request.data.data;
      });
    },
  },
  mounted() {
    this.srcPic = process.env.VUE_APP_BASE_URL;
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  margin: 4.375rem 0;
  .app-content {
    width: 80%;
    margin: 0 auto;
    .searchSlit {
      width: 100%;
      text-align: left;
    }
    .distributionLayout {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      display: flex;
      .variousLayout {
        width: 23.75rem;
        height: 7.5rem;
        margin: 0.9375rem 4rem 0.9375rem 0;
        background-color: #cee6f5;
        border-radius: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 3.4375rem;
          height: 3.4375rem;
        }
        .son_title {
          margin-left: 0.9375rem;
          font-size: 1.125rem;
          color: #0aa4ee;
          font-weight: 600;
        }
      }
    }
    .ma-_content {
      width: 100%;
      display: flex;
      .map_query {
        width: 18.75rem;
        .businessType {
          width: 17.5rem;
          background-color: #eff8ff;
          border-radius: 0.25rem;
          margin: 0.625rem auto;
          padding: 0.625rem;
          text-align: left;
          .Nav_title {
            color: #0a3cf1;
            padding: 0.625rem 0;
            border-bottom: 0.0625rem dashed #c1c1c1;
            .pause {
              padding: 0.3125rem 0.625rem;
              color: #fff;
              font-size: 0.75rem;
              border-radius: 0.25rem;
              margin-left: 0.3125rem;
              overflow-wrap: break-word;
            }
          }
          .add_parent {
            width: 100%;
            margin-top: 0.625rem;
            font-size: 0.75rem;
            .adds {
              width: 100%;
              height: 2.1875rem;
            }
          }
        }
      }
      .Maps {
        flex: 1;
        padding: 0.625rem;
        .map-wrap {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
