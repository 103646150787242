<template>
  <div class="app">
    <el-carousel
      v-if="BannerList.length > 0"
      arrow="always"
      ref="carousel"
      trigger="click"
      height="37.5rem"
      :interval="4000"
    >
      <el-carousel-item v-for="(item, index) in BannerList" :key="index">
        <img width="100%" height="100%" :src="imgUrl + item.fileUri" />
      </el-carousel-item>
    </el-carousel>
    <div v-else style="height: 38.125rem; width: 100%; overflow: hidden">
      <img
        width="100%"
        src="../../../assets/allImage/etcguide/toutu_ETCgognenngshiyongzhinan@1x.png"
        alt=""
      />
    </div>
    <commonNavigation style="width: 100%; margin-top: 0px"></commonNavigation>
    <guide></guide>
    <!-- 底部 -->
    <BottomField></BottomField>
    <keep-alive>
      <router-view v-if="isActive"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import { slideshowList } from '@/api/index';
import commonNavigation from '@/layout/components/commonNavigation/index';
import guide from '@/layout/components/HomePage/guide/index';
import BottomField from '@/layout/components/HomePage/BottomField/index.vue';
// import { headGuideList } from '@/api/HomePage/guide.js';
export default {
  components: {
    BottomField,
    commonNavigation,
    guide,
  },
  data() {
    return {
      BannerList: [], //轮播图列表
      input: '',
      isActive: true, // 控制组件的激活状态
      value: '',
      list: [],
      argument: {
        type: '',
      },
    };
  },
  activated() {
    // 组件被激活时触发，可以在此处重新创建组件或执行初始化逻辑
    // 例如，重新获取数据、重置状态等
    this.isActive = true;
  },
  deactivated() {
    // 组件被停用时触发，可以在此处进行组件的销毁或清理逻辑
    // 例如，取消订阅、清空数据等
    this.isActive = false;
  },
  // watch: {
  //   '$route.query.id': {
  //     immediate: true, // 页面加载立即监听
  //     handler(newId, oldId) {
  //       this.slideshowList(newId);
  //     },
  //   },
  // },
  watch: {
    '$route.query.id': {
      immediate: true,
      handler(newId, oldId) {
        this.rotationChartList(newId);
      },
    },
  },

  methods: {
    // 轮播图
    async rotationChartList(id) {
      const res = await slideshowList(id);
      if (res.data.code == 200) {
        if (res.data.data && res.data.data.length > 0) {
          this.BannerList = res.data.data;
        }
      }
    },

    turnToDeatils(id) {
      // this.$router.push({
      //   path:'/layout/etcguide/etcguideDetails',
      //    query:{
      //     id:id
      //   }
      // });
    },
    // getList(){
    //   headGuideList().then((request)=>{
    //     console.log('guide',request);
    //     this.list = request.data.data
    //   })
    // }
  },
  mounted() {
    this.imgUrl = process.env.VUE_APP_BASE_URL;
    // this.getList()
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  .app-content {
    width: 70%;
    margin: 0.9375rem auto;
    .searchSlit {
      width: 100%;
      text-align: left;
    }
    .distributionLayout {
      width: 100%;
      flex-wrap: wrap;
      text-align: left;
      display: flex;
      .variousLayout {
        margin-right: 4rem;
        width: 23.75rem;
        height: 7.5rem;
        margin-top: 0.9375rem;
        background-color: #cee6f5;
        border-radius: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 3.4375rem;
          height: 3.4375rem;
        }
        .son_title {
          margin-left: 0.9375rem;
          font-size: 1.125rem;
          color: #0aa4ee;
          font-weight: 600;
        }
      }
    }
    .ma-_content {
      width: 100%;
      display: flex;
      .map_query {
        width: 18.75rem;

        .businessType {
          width: 17.5rem;
          background-color: #eff8ff;
          border-radius: 0.25rem;
          margin: 0.625rem auto;
          padding: 0.625rem;
          text-align: left;
          .Nav_title {
            color: #0a3cf1;
            padding: 0.625rem 0;
            border-bottom: 0.0625rem dashed #c1c1c1;
            .pause {
              padding: 0.3125rem 0.625rem;
              color: #fff;
              font-size: 0.75rem;
              border-radius: 0.25rem;
              margin-left: 0.3125rem;
              overflow-wrap: break-word;
            }
          }
          .add_parent {
            width: 100%;
            margin-top: 0.625rem;
            font-size: 0.75rem;
            .adds {
              width: 100%;
              height: 2.1875rem;
            }
          }
        }
      }
      .Maps {
        flex: 1;
        padding: 0.625rem;
        .map-wrap {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
